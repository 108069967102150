
// Libraries
import React, { useState, useEffect } from 'react'

// Services
import UserService from '../../../services/user'

// Components
import PrivateRoute from '../../../components/privateRoute'
import Layout from '../../../components/layout'
import Seo from '../../../components/seo'
import InputFactory from '../../../components/inputFactory'
import Button from '../../../components/button'

export default function AdminProfilePage() {
	const [formData, setFormData] = useState({})

	useEffect(() => {
		const userService = new UserService()

		userService.getUser().then((response) => {
			setFormData({
				...response.data,
			})
		})
	}, [])

	return (
		<PrivateRoute admin>
			<Layout>
				<Seo title="Profile" />
				<section className="profile">
					<div className="title-container">
						<h1 className="generic-template__title outline-title">My Profile</h1>
						<Button to="/admin/profile/change-password" xsBlock>Change Password</Button>
					</div>

					<form>
						<div className="form-container">
							<InputFactory
								type="text"
								name="first_name"
								label="First Name"
								value={formData['first_name']}
								readOnly
								labelEndeavour
							/>
							<InputFactory
								type="text"
								name="last_name"
								label="Surname"
								value={formData['last_name']}
								readOnly
								labelEndeavour
							/>
							<InputFactory
								type="text"
								name="email"
								label="Email Address"
								value={formData['email']}
								readOnly
								labelEndeavour
							/>
						</div>
					</form>
				</section>
			</Layout>
		</PrivateRoute>
	)
}
